var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "extra-component-quill-editor-demo" } },
    [
      _vm._m(0),
      _c("quill-editor-theme-snow"),
      _c("quill-editor-theme-bubble"),
      _c("quill-editor-custom-toolbar"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-4" }, [
      _c(
        "a",
        {
          attrs: {
            href: "https://surmon-china.github.io/vue-quill-editor/",
            target: "_blank",
            rel: "nofollow",
          },
        },
        [_vm._v("Vue-Quill-Editor")]
      ),
      _vm._v(
        " editor component for Vue, support SPA and SSR. Read full documnetation "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://quilljs.com/docs/quickstart/",
            target: "_blank",
            rel: "nofollow",
          },
        },
        [_vm._v("here")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }