var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Custom Toolbar", "code-toggler": "" } },
    [
      _c("p", { staticClass: "mb-5" }, [
        _vm._v("You have freedom to create your own custom toolbar"),
      ]),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full md:w-1/2" },
          [
            _c(
              "quill-editor",
              {
                attrs: { options: _vm.editorOption },
                model: {
                  value: _vm.content,
                  callback: function ($$v) {
                    _vm.content = $$v
                  },
                  expression: "content",
                },
              },
              [
                _c(
                  "div",
                  {
                    attrs: { slot: "toolbar", id: "toolbar" },
                    slot: "toolbar",
                  },
                  [
                    _c("button", { staticClass: "ql-bold" }, [_vm._v("Bold")]),
                    _c("button", { staticClass: "ql-italic" }, [
                      _vm._v("Italic"),
                    ]),
                    _c("select", { staticClass: "ql-size" }, [
                      _c("option", { attrs: { value: "small" } }),
                      _c("option", { attrs: { selected: "" } }),
                      _c("option", { attrs: { value: "large" } }),
                      _c("option", { attrs: { value: "huge" } }),
                    ]),
                    _c("select", { staticClass: "ql-font" }, [
                      _c("option", { attrs: { selected: "selected" } }),
                      _c("option", { attrs: { value: "serif" } }),
                      _c("option", { attrs: { value: "monospace" } }),
                    ]),
                    _c("button", {
                      staticClass: "ql-script",
                      attrs: { value: "sub" },
                    }),
                    _c("button", {
                      staticClass: "ql-script",
                      attrs: { value: "super" },
                    }),
                    _c(
                      "button",
                      {
                        staticStyle: { width: "auto" },
                        on: { click: _vm.customButtonClick },
                      },
                      [_vm._v("Click here")]
                    ),
                  ]
                ),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-full md:w-1/2 flex" },
          [
            _c("prism", { staticClass: "rounded-lg" }, [
              _vm._v(" " + _vm._s(_vm.content) + " "),
            ]),
          ],
          1
        ),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <quill-editor v-model="content" :options="editorOption">\n\n    <div id="toolbar" slot="toolbar">\n\n      <!-- Add a bold button -->\n      <button class="ql-bold">Bold</button>\n      <button class="ql-italic">Italic</button>\n\n      <!-- Add font size dropdown -->\n      <select class="ql-size">\n        <option value="small"></option>\n        <!-- Note a missing, thus falsy value, is used to reset to default -->\n        <option selected></option>\n        <option value="large"></option>\n        <option value="huge"></option>\n      </select>\n\n      <select class="ql-font">\n        <option selected="selected"></option>\n        <option value="serif"></option>\n        <option value="monospace"></option>\n      </select>\n\n      <!-- Add subscript and superscript buttons -->\n      <button class="ql-script" value="sub"></button>\n      <button class="ql-script" value="super"></button>\n\n      <!-- You can also add your own -->\n      <button style="width:auto" @click="customButtonClick">Click here</button>\n    </div>\n  </quill-editor>\n</template>\n\n<script>\n// require styles\nimport \'quill/dist/quill.core.css\'\nimport \'quill/dist/quill.snow.css\'\nimport \'quill/dist/quill.bubble.css\'\n\nimport { quillEditor } from \'vue-quill-editor\'\n\nexport default {\n  data() {\n    return {\n      editorOption: {\n        modules: {\n          toolbar: \'#toolbar\'\n        }\n      },\n      content: `...`,\n    }\n  },\n  components: {\n    quillEditor,\n  },\n  methods: {\n    customButtonClick() {\n      alert("Button clicked!");\n    }\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }