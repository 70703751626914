var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Theme Snow", "code-toggler": "" } },
    [
      _c("p", { staticClass: "mb-5" }, [
        _vm._v("Snow is a clean, flat toolbar theme"),
      ]),
      _c("quill-editor", {
        model: {
          value: _vm.content,
          callback: function ($$v) {
            _vm.content = $$v
          },
          expression: "content",
        },
      }),
      _c("prism", { staticClass: "rounded-lg" }, [
        _vm._v(" " + _vm._s(_vm.content) + " "),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          "\n<template>\n  <quill-editor v-model=\"content\"></quill-editor>\n</template>\n\n<script>\n// require styles\nimport 'quill/dist/quill.core.css'\nimport 'quill/dist/quill.snow.css'\nimport 'quill/dist/quill.bubble.css'\n\nimport { quillEditor } from 'vue-quill-editor'\n\nexport default {\n  data() {\n    return {\n      content: `...`,\n    }\n  },\n  components: {\n    quillEditor,\n  }\n}\n</script>\n        "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }